// NPM Dependencies
import React from "react";
import Moment from "moment-timezone";

// Local Dependencies
import DeleteButton from "../misc/Delete";
import LoadingCircle from "../misc/Loading";

function DrawAccess(props) {
  if (props.accesses === undefined) {
    return <LoadingCircle />;
  }

  const filteredAccessess = props.accesses.filter((access) => {
    for (const product of props.products) {
      if (access.data().product_id === product.id) {
        return true;
      }
    }
    return false;
  });

  if (filteredAccessess && filteredAccessess.length > 0) {
    return filteredAccessess.map((doc) => {
      const data = doc.data();

      const RLSAccessDisplay = data.rls ? (
        data.rls.map((dim) => {
          return (
            <div style={accessTextStyle}>
              <p style={{ margin: "0" }}>
                <b>{dim.displayName} Access:</b>
              </p>
              {dim.rows.map((row, i) => (
                <span key={i} style={{ color: "red" }}>
                  {row.value}
                  {i + 1 !== dim.rows.length ? ", " : ""}
                </span>
              ))}
            </div>
          );
        })
      ) : (
        <></>
      );

      const product = props.products.filter((p) => data.product_id === p.id)[0];
      const isExpired = data.expiration_date.toDate() < Date.now();

      return (
        <div className="listing" style={{ display: "block" }} key={doc.id}>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            {/* access title + access details */}
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {/* access title */}
              <span style={{ display: "flex", flexDirection: "row" }}>
                <h4
                  className="font-weight-bold"
                  style={{
                    justifySelf: "start",
                    margin: 0,
                    fontSize: "18px",
                    color: isExpired ? "#555" : "#333",
                  }}
                >
                  {product.data().product_name}
                </h4>
                {isExpired ? (
                  <span style={{ color: "darkred", marginLeft: "0.25rem" }}>(expired)</span>
                ) : (
                  <React.Fragment />
                )}
              </span>
              {/* access details */}
              <span style={{ display: "flex", flexWrap: "wrap" }}>
                <i
                  style={{
                    ...accessTextStyle,
                    color: isExpired ? "#555" : "#333",
                    marginRight: "0.5rem",
                  }}
                >
                  FPID: {product.id}
                </i>
                <span> • </span>
                <i
                  style={{
                    ...accessTextStyle,
                    color: isExpired ? "#555" : "#333",
                    marginRight: "0.5rem",
                    marginLeft: "0.5rem",
                  }}
                >
                  SPID: {product.data().shopify_product_id}
                </i>
                <span> • </span>
                <h4
                  style={{
                    ...accessTextStyle,
                    color: isExpired ? "#555" : "#333",
                    marginLeft: "0.5rem",
                  }}
                >
                  {/* Brightfield based in Chicago, default timezone: "America/Chicago" */}
                  {data.expiration_date && data.expiration_date.toDate ? (
                    <span>
                      {Moment(data.expiration_date.toDate()).tz("America/Chicago").format("MM/DD/YY HH:mm")} CST
                    </span>
                  ) : (
                    "Invalid expiration date!"
                  )}
                </h4>
              </span>
            </span>

            {/* pause/resume access buttons */}
            <span
              style={{
                marginLeft: "auto",
                display: "flex",
                flexDirection: "row",
                marginTop: "auto",
                marginBottom: "auto",
                flex: "none",
              }}
            >
              <button
                className="btn btn-light"
                onClick={props.pauseAccess.bind(this, doc.id)}
                style={{ marginRight: ".375rem", marginBottom: "auto" }}
                title={`${data.is_paused ? "Resume access" : "Pause access"} "${product.data().product_name}"`}
                disabled={isExpired && data.is_paused}
              >
                {data.is_paused ? <i className="fas fa-play"></i> : <i className="fas fa-stop"></i>}
              </button>
              <DeleteButton
                object_name={"access"}
                delete={props.deleteAccess.bind(this, doc.id)}
                title={`Remove access "${product.data().product_name}" from this company`}
              />
            </span>
          </span>
          {RLSAccessDisplay}
        </div>
      );
    });
  }
  return (
    <div className={`alert alert-warning`} role="alert" style={{ width: "100%" }}>
      There are no products to display.
    </div>
  );
}

const accessTextStyle = {
  fontFamily: "Montserrat, sans-serif",
  justifySelf: "start",
  alignSelf: "center",
  margin: 0,
  fontSize: "13px",
  fontWeight: "normal",
};

export default DrawAccess;
