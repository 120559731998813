import React, { Component } from "react";

export default class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggled: false,
    };
  }

  toggleState = () => {
    this.setState({
      isToggled: !this.state.isToggled,
    });
  };

  render() {
    const children = React.Children.map(this.props.children, (child) => {
      if (
        child.type === ModalBody ||
        child.type === ShowModalButton ||
        child.type === CloseModalButton
      ) {
        return React.cloneElement(child, {
          toggleState: this.toggleState,
          isToggled: this.state.isToggled,
        });
      } else {
        return child;
      }
    });
    return children;
  }
}

export class ShowModalButton extends Component {
  render() {
    return (
      <div
        className={this.props.className}
        style={this.props.style}
        onClick={this.props.toggleState}
        title={this.props.title}
      >
        {this.props.children}
      </div>
    );
  }
}

export class CloseModalButton extends Component {
  render() {
    return (
      <div
        className={this.props.className}
        style={this.props.style}
        onClick={() => {
          // Reason for two functions is to allow both passed onClick
          // to be ran as well as injected toggleState which closes the modal
          if (this.props.onClick) this.props.onClick();
          if (this.props.toggleState) this.props.toggleState();
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export class ModalBody extends Component {
  // Maybe a bit overkill but adding ability to scan through nests of divs/spans
  // to find CloseModalButton for more flexiblilty
  getNestedCompoundElement = (parentElementsChildren, childType, newProps) => {
    return React.Children.map(parentElementsChildren, (child) => {
      if (child.type === childType) {
        return React.cloneElement(child, newProps);
      } else if (child.type === "span" || child.type === "div") {
        return (
          <child.type className={child.className} style={child.style}>
            {this.getNestedCompoundElement(
              child.props.children,
              CloseModalButton,
              {
                toggleState: this.props.toggleState,
              }
            )}
          </child.type>
        );
      } else {
        return child;
      }
    });
  };

  render() {
    const children = React.Children.map(this.props.children, (child) => {
      if (child.type === CloseModalButton) {
        return React.cloneElement(child, {
          toggleState: this.props.toggleState,
        });
      } else if (child.type === "span" || child.type === "div") {
        return (
          <child.type
            className={child.props.className}
            style={child.props.style}
          >
            {this.getNestedCompoundElement(
              child.props.children,
              CloseModalButton,
              {
                toggleState: this.props.toggleState,
              }
            )}
          </child.type>
        );
      } else {
        return child;
      }
    });
    return (
      <div>
        {this.props.isToggled ? (
          <div style={backgroundStyle}>
            <div style={modalWrapper}>
              <div style={modal}>{children}</div>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    );
  }
}

// Styles --->
const backgroundStyle = {
  position: "fixed",
  top: "0",
  left: "0",
  height: "100%",
  width: "100%",
  backgroundColor: "rgba(0.1, 0.1, 0.1, 0.5)",
  zIndex: 997,
};

const modalWrapper = {
  display: "flex",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
};

const modal = {
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  color: "black",
  fontSize: "13px",
  padding: "1rem",
  borderRadius: ".25rem",
};
