import React from "react";

function LoadingCircle(props) {
  return (
    <div
      style={{ marginTop: "3rem", ...props.style }}
      className={props.className || "d-flex justify-content-center"}
    >
      <div
        className={`spinner-border ${props.small ? "spinner-border-sm" : ""}`}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default LoadingCircle;
