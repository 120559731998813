/** Everything related to UMA products. */

import { collection, DocumentData, DocumentReference, getDocs, QueryDocumentSnapshot } from "firebase/firestore";
import { db } from "../firebase";

export interface IDimsProduct {
  displayName: string;
  columnId: string;
  columnName: string;
  dimTable: string;
}

export interface IProductRLS {
  accessTable: string;
  dims: Array<IDimsProduct>;
}

const getProducts = async () => {
  const productsRef = collection(db, "products").withConverter(productConverter);
  const productDocs = await getDocs(productsRef);
  return productDocs.docs.map((productDoc) => productDoc.data());
}

// TODO: finish implementing
const getProductsListener = async () => {
}

class Product {
  productName: string;
  shopifyProductId: string;
  group?: number;
  rls?: IProductRLS;
  collection?: string;
  docId: string;
  docRef: DocumentReference<DocumentData>;

  constructor(initOptions: {
    productName: string;
    shopifyProductId: string;
    group?: number;
    rls?: IProductRLS;
    collection?: string;
    docId: string;
    docRef: DocumentReference<DocumentData>;
  }) {
    this.productName = initOptions.productName;
    this.shopifyProductId = initOptions.shopifyProductId;
    this.group = initOptions.group;
    this.rls = initOptions.rls;
    this.collection = initOptions.collection;
    this.docId = initOptions.docId;
    this.docRef = initOptions.docRef;
  };

  static fromFirestore = (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data();
    return new Product({
      productName: data.product_name,
      shopifyProductId: data.shopify_product_id,
      group: data.group,
      rls: data.rls,
      collection: data.collection,
      docId: snapshot.id,
      docRef: snapshot.ref
    });
  };
}

/** Use to convert Firestore data to `Company` objects and vise versa. */
const productConverter = {
  toFirestore(product: Product): DocumentData {
    return {
      product_name: product.productName,
      shopify_product_id: product.shopifyProductId,
      group: product.group,
      rls: product.rls,
      collection: product.collection
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot
  ): Product {
    return Product.fromFirestore(snapshot);
  },
};

export default Product;
export { productConverter, getProducts };
