/** Everything related to UMA product access. */

import { collection, DocumentData, DocumentReference, QueryDocumentSnapshot } from "firebase/firestore";
import { IDims } from "../../components/pages/company/access/RLSDims";
import { db } from "../firebase";
import Company from "../uma/companies";

export interface IDimsAccess {
  displayName: string;
  columnName: string;
  dimTable: string;
  rows: Array<string>;
}

class Access {
  productId: string;
  expirationDate: Date;
  isPaused: boolean;
  rls?: IDims[];
  docId?: string;
  docRef?: DocumentReference<DocumentData>;

  constructor(initOptions: {
    productId: string;
    expirationDate: Date;
    isPaused?: boolean;
    rls?: IDims[];
    docId?: string;
    docRef?: DocumentReference<DocumentData>;
  }) {
    this.productId = initOptions.productId;
    this.expirationDate = initOptions.expirationDate;
    this.isPaused = initOptions.isPaused || false;
    this.rls = initOptions.rls;
    this.docId = initOptions.docId;
    this.docRef = initOptions.docRef;
  };

  static fromFirestore = (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data();
    return new Access({
      productId: data.product_id,
      expirationDate: data.expiration_date,
      isPaused: data.is_paused,
      docId: snapshot.id,
      docRef: snapshot.ref
    });
  };
}

/** Use to convert Firestore data to `Client` objects and vise versa. */
const accessConverter = {
  toFirestore(access: Access): DocumentData {
    return {
      product_id: access.productId,
      expiration_date: access.expirationDate,
      is_paused: access.isPaused,
      rls: access.rls || null
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot
  ): Access {
    return Access.fromFirestore(snapshot);
  },
}

export default Access;
export { accessConverter };
