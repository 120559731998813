// NPM Dependencies
import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";

// Local Dependencies
import DrawAdditionalUsers from "../../company/Users";
import DrawAccesses from "../../company/Accesses";
import CustomInput from "../../misc/CustomInput";
import Alert from "../../misc/Alert";
import DeleteButton from "../../misc/Delete";
import DrawLogs from "../../Logs";
import Breadcrumbs from "../../misc/Breadcrumbs";
import PageHeader from "../../misc/PageHeader";
import { db } from "../../../lib/firebase-compat";

// Context
import CloudFunctions from "../../../Context";

class CompanyPage extends Component {
  static contextType = CloudFunctions;

  state = {
    redirect: null,
    doc: null,
    products: [], // list of all products available
    accesses: undefined, // list of products the company has access to
    users: undefined, // users a part of this company
    logs: undefined, // logs specific to this company
    loadingMoreLogs: false, // logs loading circle controlled by this boolean
    maxLogsReached: false, // "Show more" button disabled if this is true
    inputs: { company_name: undefined },
    alert: { msg: "", type: null },
    companyModalToggle: false,
  };

  initilizeListeners = () => {
    const companies_collection = db.collection("companies");
    const doc = companies_collection.doc(this.props.companyId);

    const products = db.collection("products");
    const users = db
      .collection("users")
      .where("companies", "array-contains", this.props.companyId);
    const accesses = doc.collection("access");
    const logs = doc.collection("logs").orderBy("timestamp", "desc").limit(10);

    this.company_unsub = doc.onSnapshot(
      (snapshot) => {
        this.setState({
          doc: snapshot,
        });
      },
      (err) => {
        console.log(`encountered error: ${err}`);
      }
    );
    this.products_unsub = products.onSnapshot((snapshot) => {
      this.setState({
        products: snapshot.docs,
      });
    });
    this.users_unsub = users.onSnapshot((snapshot) => {
      this.setState({
        users: snapshot.docs.filter((doc) => doc.id !== "_all_users"),
      });
    });
    this.accesses_unsub = accesses.onSnapshot((snapshot) => {
      this.setState({
        accesses: snapshot.docs,
      });
    });
    this.logs_unsub = logs.onSnapshot((snapshot) => {
      this.setState({
        logs: snapshot.docs,
        maxLogsReached: snapshot.size < 10,
      });
    });
  };

  // fetch document details from Firestore and initialize listeners
  componentDidMount() {
    this.shopify = this.context;
    this.initilizeListeners();
  }

  componentWillUnmount() {
    if (this.company_unsub) this.company_unsub();
    if (this.products_unsub) this.products_unsub();
    if (this.users_unsub) this.users_unsub();
    if (this.accesses_unsub) this.accesses_unsub();
    if (this.cancelAlert) this.cancelAlert();
    if (this.logs_unsub) this.logs_unsub();
  }

  getProduct = (pid) => {
    return this.state.products.filter((product) => {
      return product.id === pid;
    })[0];
  };

  handleInputChange = (e) => {
    if (e.target.id === "company_name") {
      this.setState({
        inputs: {
          [e.target.id]: e.target.value,
        },
      });
    }
  };

  setAlert = (msg, alertType, time) => {
    this.cancelAlert();
    this.setState({
      alert: {
        msg: msg,
        type: alertType,
      },
    });
    this.timer = setTimeout(() => {
      this.setState({
        alert: {
          msg: "",
        },
      });
    }, time);
  };

  cancelAlert = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  };

  createGlobalLog = (logType, specifics = {}) => {
    if (!logType) {
      throw new Error("logType not specified");
    }
    const newLog = db.collection("companies_logs").doc();
    const promise = newLog.set({
      log_type: logType,
      timestamp: new Date(),
      admin_id: this.props.user.uid,
      admin_email: this.props.user.email,
      specifics: {
        company_id: this.state.doc.id,
        company_name: this.state.doc.data().company_name,
        ...specifics,
      },
    });
    return { promise: promise, doc: newLog };
  };

  createLocalLog = (logType, specifics = {}, status = "pending") => {
    if (!logType) {
      throw new Error("logType not specified");
    }
    const newLog = this.state.doc.ref.collection("logs").doc();
    const promise = newLog.set({
      log_type: logType,
      status: status,
      timestamp: new Date(),
      admin_id: this.props.user.uid,
      admin_email: this.props.user.email,
      specifics: {
        company_id: this.state.doc.id,
        company_name: this.state.doc.data().company_name,
        ...specifics,
      },
    });
    return { promise: promise, doc: newLog };
  };

  submitNameChange = (e) => {
    const input = this.state.inputs["company_name"];
    if (this.state.doc && input !== undefined) {
      const data = this.state.doc.data();
      const name = input ? input : data ? data.company_name : "";
      if (
        this.state.doc.data()["company_name"] !==
        this.state.inputs["company_name"]
      ) {
        const specifics = {
          old_name: data["company_name"],
          new_name: name,
        };
        this.createLocalLog("company_name_change", specifics, "");
        this.createGlobalLog("company_name_change", specifics);
        this.state.doc.ref
          .update({ company_name: name })
          .then(() => {
            this.setAlert("Successfully updated", "primary", 3000);
            this.setState({
              companyModalToggle: false,
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  toggleCompanyModal = () => {
    this.setState({
      companyModalToggle: !this.state.companyModalToggle,
    });
  };

  delete = async () => {
    if (this.state.doc) {
      const all_companies = await db
        .doc("companies/_all_companies")
        .get();
      if (all_companies.exists) {
        all_companies.ref.update({
          companies: ((all_companies.data() || {}).companies || []).filter(
            (company_name) =>
              company_name !== this.state.doc.data().company_name
          ),
        });
      }
      const log = this.createLocalLog("company_deleted");
      await log.promise;
      log.doc.update({
        status: "resolved",
      });
      this.createGlobalLog("company_deleted");
      // iterate through existing users and remove them from Shopify
      this.state.users.forEach((user) => {
        this.deleteUser(user.ref.id);
      });
      this.state.doc.ref.delete();
      this.setState({
        redirect: "/companies",
      });
    }
  };

  removeDuplicates = (arr) => {
    return [...new Set(arr)];
  };

  pauseAccess = async (id, e) => {
    const access = await this.state.doc.ref.collection("access").doc(id).get();

    if (!access.exists) {
      console.error(`no access document found for id ${id}`);
      return;
    }

    const accessData = access.data();
    const newState = !accessData.is_paused;

    const productDoc = this.getProduct(accessData.product_id);

    access.ref.update({
      is_paused: newState,
    });

    const newLog = this.createLocalLog("access_toggled", {
      access_id: access.id,
      access_details: {
        status: newState,
        product_id: accessData.product_id,
        product_name: productDoc.data().product_name,
      },
    });
    await newLog.promise;

    // handle RLS access
    if (newState && accessData.rls) {
      // access was paused
      try {
        const accessTable = this.getProduct(accessData.product_id).data().rls.accessTable;
        this.state.users.forEach((user) => {
          const rlsAccessRemoval = {
            accessTable: accessTable,
            userId: user.ref.id
          };
          this.shopify.removeRlsAccessFromClient(rlsAccessRemoval).then((result) => {
            console.log("RLS removal job init successful");
            console.log(result);
          }).catch((err) => {
            console.log("RLS removal job init failed");
            console.log(err);
          });
        });
      } catch (err) {
        console.error(`encountered error when trying to pause RLS access from users: ${err}`);
        console.error(err);
      }
    } else if (!newState && accessData.rls) {
      // access resumed
      try {
        const rlsProduct = this.getProduct(accessData.product_id).data();
        const accessTable = rlsProduct.rls.accessTable;
        accessData.rls.forEach((rlsDim) => {
          this.state.users.forEach((user) => {
            const rlsGrant = {
              accessTable: accessTable,
              userId: user.ref.id,
              dimName: rlsDim.dimTable.split(".")[2], // d_location
              dimValues: rlsDim.rows
            };
            this.shopify.grantRlsAccessToClient(rlsGrant).then((result) => {
              console.log("RLS grant successful");
              console.log(result);
            }).catch((err) => {
              console.log("RLS grant failed");
              console.log(err);
            });
          });
        });
      } catch (err) {
        console.error(`encountered error when trying to resume RLS access from users: ${err}`);
        console.error(err);
      }
    }

    this.shopify
      .updateCompanyAccess({
        company_id: this.state.doc.id,
        product_id: accessData.product_id,
        new_state: newState,
        log_id: newLog.doc.id,
      })
      .then((response) => {
        console.log(response);
        if ("data" in response && response.data.message === "0 jobs created") {
          newLog.doc.update({
            status: "pointless",
          });
        } else {
          newLog.doc.update({
            status: "in_queue",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteAccess = async (id, e) => {
    const access = await this.state.doc.ref.collection("access").doc(id).get();

    if (!access.exists) {
      console.error(`no access document found for id ${id}`);
      return;
    }

    const accessData = access.data();

    const newLog = this.createLocalLog("access_deleted", {
      access_id: access.id,
      access_details: {
        status: false,
        product_id: accessData.product_id,
        product_name: this.getProduct(accessData.product_id).data()
          .product_name,
      },
    });
    await newLog.promise;

    // handle RLS access
    if (accessData.rls) {
      try {
        console.log(this.getProduct(accessData.product_id));
        const accessTable = this.getProduct(accessData.product_id).data().rls.accessTable;
        console.log(accessTable);
        this.state.users.forEach((user) => {
          const rlsAccessRemoval = {
            accessTable: accessTable,
            userId: user.ref.id
          };
          this.shopify.removeRlsAccessFromClient(rlsAccessRemoval).then((result) => {
            console.log("RLS removal job init successful");
            console.log(result);
          }).catch((err) => {
            console.log("RLS removal job init failed");
            console.log(err);
          });
        });
      } catch (err) {
        console.error(`encountered error when trying to remove RLS access from users: ${err}`);
        console.error(err);
      }
    }

    this.shopify
      .deleteCompanyAccess({
        product_id: accessData.product_id,
        company_id: this.state.doc.id,
        log_id: newLog.doc.id,
      })
      .then((response) => {
        if ("data" in response && response.data.message === "0 jobs created") {
          newLog.doc.update({
            status: "pointless",
          });
        } else {
          newLog.doc.update({
            status: "in_queue",
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });

    access.ref.delete();
  };

  editUser = (id, e) => {
    this.setState({
      redirect: `/company/${this.state.doc.id}/edit-user/${id}`,
    });
  };

  deleteUser = async (id, e) => {
    const users_collection = db.collection("users");
    const [all_users_doc, doc_to_delete] = await Promise.allSettled([
      users_collection.doc("_all_users").get(),
      users_collection.doc(id).get(),
    ]);
    const [all_users_data, doc_data] = [
      all_users_doc.value.data(),
      doc_to_delete.value.data(),
    ];
    console.log(all_users_data, doc_data);

    const newLog = this.createLocalLog("user_deleted", {
      user_id: id,
      user_details: doc_data,
    });
    await newLog.promise;

    const filterCachedEmails = async (email, listOfCompanies) => {
      const companies_collection = db.collection("companies");
      const fetched_documents = await Promise.allSettled(
        listOfCompanies.map(async (company_id) => {
          return companies_collection.doc(company_id).get();
        })
      );
      fetched_documents.forEach((doc) => {
        if (doc.value.exists) {
          doc.value.ref.update({
            users_emails: (doc.value.data().users_emails || []).filter(
              (user_email) => user_email !== email
            ),
          });
        } else {
          console.log("filterCachedEmails call encountered invalid company");
        }
      });
    };

    if (doc_data.original_company_id === this.state.doc.id) {
      // handle RLS accesses
      const rlsAccesses = this.state.accesses
        .filter((accessDoc) => accessDoc.data().rls)
        .map((accessDoc) => accessDoc.data());
      rlsAccesses.forEach((rlsAccess) => {
        console.log(rlsAccess.product_id);
        const rlsProduct = this.getProduct(rlsAccess.product_id);
        const rlsRemoval = {
          accessTable: rlsProduct.data().rls.accessTable,
          userId: id
        };
        console.log(rlsRemoval);
        this.shopify.removeRlsAccessFromClient(rlsRemoval).then((result) => {
          console.log("RLS removal successful");
          console.log(result);
        }).catch((err) => {
          console.log("RLS removal failed");
          console.log(err);
        });
      });
      if (all_users_doc.value.exists) {
        all_users_doc.value.ref.update({
          users: all_users_data.users.filter(
            (user) => user.email !== doc_data.email
          ),
        });
      }
      this.shopify
        .deleteCustomer({
          customer_id: doc_data.associated_shopify_customer
            ? doc_data.associated_shopify_customer.customer.id
            : -1,
          company_id: this.state.doc.id,
          user_id: id,
          log_id: newLog.doc.id,
        })
        .then(async (response) => {
          if (response.status && response.status === "error") {
            newLog.doc.update({
              status: "failed",
              status_message: response.error,
            });
          } else {
            if ((await this.state.doc.ref.get()).exists) {
              const cached_user_emails = this.state.doc.data().users_emails;
              this.state.doc.ref.update({
                users_emails: (cached_user_emails || []).filter(
                  (email) => email !== doc_data.email
                ),
              });
              filterCachedEmails(doc_data.email, doc_data.companies);
            }
            newLog.doc.update({
              status: "in_queue",
            });
          }
        });
      doc_to_delete.value.ref.delete();
    } else {
      const cached_user_emails = this.state.doc.data().users_emails;
      const new_access = { ...doc_data.access, [this.state.doc.id]: [] };
      this.state.doc.ref.update({
        users_emails: (cached_user_emails || []).filter(
          (email) => email !== doc_data.email
        ),
      });
      doc_to_delete.value.ref.update({
        companies: doc_data.companies.filter(
          (company_id) => company_id !== this.state.doc.id
        ),
        access: new_access,
      });
      this.shopify.updateCustomer({
        customer_id: doc_data.associated_shopify_customer
          ? doc_data.associated_shopify_customer.customer.id
          : -1,
        new_access: Object.keys(new_access)
          .map((company_id) => new_access[company_id])
          .reduce((acc, arr) => acc.concat(arr))
          .map(
            (access_id) => this.getProduct(access_id).data().shopify_product_id
          ),
        company_id: this.state.doc.id,
        companies: doc_data.companies.filter(
          (company_id) => company_id !== this.state.doc.id
        ),
        user_id: id,
        log_id: newLog.id,
      });
      newLog.doc.update({
        status: "in_queue",
        status_message: "User deleted from not its original company",
      });
    }
  };

  showMoreLogs = () => {
    this.setState({
      loadingMoreLogs: true,
    });

    const companies_collection = db.collection("companies");
    const doc = companies_collection.doc(this.props.companyId);

    const newLimit = this.state.logs.length + 10;
    const logs = doc
      .collection("logs")
      .orderBy("timestamp", "desc")
      .limit(newLimit);

    this.logs_unsub();
    this.logs_unsub = logs.onSnapshot((snapshot) => {
      this.setState({
        logs: snapshot.docs,
        loadingMoreLogs: false,
        maxLogsReached: snapshot.size < newLimit,
      });
    });
  };

  // This is starting to get a little unreadable :(
  // TODO: Split this into smaller more reuseable components
  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    let data = this.state.doc ? this.state.doc.data() : null;
    let id = this.state.doc ? this.state.doc.id : "";
    return (
      <div className="split-view">
        <div className="m0 listings-wrapper left">
          <Breadcrumbs
            style={{ marginTop: "1rem" }}
            pathways={[["Companies", "/companies"], ["Company"]]}
          />
          <PageHeader
            small={"Company"}
            big={data ? data.company_name : ""}
            tiny={id}
          />

          <div style={{ marginTop: "1rem" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <button
                className={`btn btn-${
                  this.state.companyModalToggle ? "secondary" : "light"
                } btn-sm`}
                style={{ marginLeft: "auto" }}
                onClick={this.toggleCompanyModal}
              >
                {this.state.companyModalToggle
                  ? "Cancel"
                  : "Change Company Name"}
              </button>
              <span style={{ marginLeft: "1rem" }}>
                <DeleteButton
                  title={`Delete company "${data ? data.company_name : ""}"`}
                  object_name={"company"}
                  delete={this.delete}
                />
              </span>
            </div>

            {this.state.companyModalToggle ? (
              <div
                style={{ display: "flex", alignItems: "flex-end", margin: 0 }}
              >
                <span style={{ width: "100%" }}>
                  <CustomInput
                    name={"company_name"}
                    label={"Company Name"}
                    onChange={this.handleInputChange}
                    value={
                      this.state.inputs["company_name"] !== undefined
                        ? this.state.inputs["company_name"]
                        : data
                        ? data.company_name
                        : ""
                    }
                    width={"100%"}
                  />
                </span>
                <button
                  className="btn btn-light btn-sm"
                  style={{
                    marginLeft: "1rem",
                    height: "calc(1.5em + .75rem + 5px)",
                  }}
                  onClick={this.submitNameChange}
                >
                  Confirm
                </button>
              </div>
            ) : (
              <React.Fragment />
            )}

            <Alert
              msg={this.state.alert.msg}
              alertType={this.state.alert.type}
            />
          </div>

          <div style={{ marginTop: "3rem" }}>
            <div style={{ display: "flex" }}>
              <h5 className="font-weight-bold">Access</h5>
              <span style={{ marginLeft: "auto" }}>
                <Link
                  title={"Add new access to this company"}
                  to={`/company/${id}/new-access`}
                >
                  + New Access
                </Link>
              </span>
            </div>
            <DrawAccesses
              products={this.state.products}
              accesses={this.state.accesses}
              pauseAccess={this.pauseAccess}
              deleteAccess={this.deleteAccess}
            />
          </div>

          <div style={{ marginTop: "3rem" }}>
            <div style={{ display: "flex" }}>
              <h5 className="font-weight-bold">All Users</h5>
              <span style={{ marginLeft: "auto" }}>
                <Link
                  title={"Add new user to this company"}
                  to={`/company/${id}/new-user`}
                >
                  + Add single user
                </Link>
                {/* <Link
                to={`/company/${id}/add-bulk`}
                style={{ marginLeft: "1rem" }}
              >
                + Bulk add users
              </Link> */}
              </span>
            </div>
            <DrawAdditionalUsers
              editUser={this.editUser}
              delete={this.deleteUser}
              users={this.state.users}
              db={db}
            />
          </div>
        </div>
        <DrawLogs
          className="right"
          style={{ marginTop: "4rem" }}
          showMore={this.showMoreLogs}
          showMoreDisabled={this.state.maxLogsReached}
          isLoadingMoreLogs={this.state.loadingMoreLogs}
          logs={this.state.logs}
        />
      </div>
    );
  }
}

export default CompanyPage;
