/** Everything related to UMA clients. */
import { db } from "../firebase";
import { DocumentData, DocumentReference, QueryDocumentSnapshot, where, query, collection, doc, getDocs, getDoc, addDoc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import Company from "./companies";

interface IClient {
  access: Record<string, Array<string>>;
  access_history: Record<string, Array<string>>;
  associated_shopify_customer: Record<string, any>;
  email: string;
  first_name: string;
  last_name: string;
  original_company_id: string;
  companies: Array<string>;
}

// TODO: finish implementing
class Client {
  access: Record<string, Array<string>>;
  accessHistory: Record<string, Array<string>>;
  associatedShopifyCustomer: Record<string, any>;
  email: string;
  firstName: string;
  lastName: string;
  originalCompanyId: string;
  companies: Array<string>;
  docId: string;
  docRef: DocumentReference<DocumentData>;

  constructor(initOptions: {
    access: Record<string, Array<string>>;
    accessHistory: Record<string, Array<string>>;
    associatedShopifyCustomer: Record<string, any>;
    email: string;
    firstName: string;
    lastName: string;
    originalCompanyId: string;
    companies: Array<string>;
    docId: string;
    docRef: DocumentReference<DocumentData>;
  }) {
    this.access = initOptions.access;
    this.accessHistory = initOptions.accessHistory;
    this.associatedShopifyCustomer = initOptions.associatedShopifyCustomer;
    this.email = initOptions.email;
    this.firstName = initOptions.firstName;
    this.lastName = initOptions.lastName;
    this.originalCompanyId = initOptions.originalCompanyId;
    this.companies = initOptions.companies;
    this.docId = initOptions.docId;
    this.docRef = initOptions.docRef;
  }

  static fromFirestoreDoc = (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data();
    return new Client({
      access: data.access,
      accessHistory: data.access_history,
      associatedShopifyCustomer: data.associated_shopify_customer,
      email: data.email,
      firstName: data.first_name,
      lastName: data.last_name,
      originalCompanyId: data.original_company_id,
      companies: data.companies,
      docId: snapshot.id,
      docRef: snapshot.ref
    });
  };

  // TODO: finish implementing later
  getCompanies = async (): Promise<Array<Company> | undefined> => {
    return undefined;
  };

  // TODO: finish implementing later
  // since this delete function is on the client, we're assuming we're deleting
  // the user completely, meaning from all companies. Company.deleteClient will
  // be a function designed to delete the user only completely if the user isn't
  // a part of multiple companies
  delete = async () => {
    // laid out steps to delete here, but actual working example of these steps
    // are in the EditCompany component.
    // 1. get _all_users meta document
    // 2. fetch all the companies the user is in
    // 3. filter out users email from all the companies the user is in
    // 4. create & await a local company log with type "user_deleted" in all of the companies the user is in
    // 5. filter out this users email from the _all_users meta document
    // 6. make an API call to the cloud functions to delete the user
    // making sure to update the log with "failed" status if it fails,
    // and updating the log status to "in_queue" if its successful
    // 7. delete the user document
  };

  getAccess = () => {};

  grantNewAccess = () => {};
}

/** Use to convert Firestore data to `Client` objects and vise versa. */
const clientCoverter = {
  toFirestore(client: Client): DocumentData {
    return {
      access: client.access,
      access_history: client.accessHistory,
      associated_shopify_customer: client.associatedShopifyCustomer,
      email: client.email,
      first_name: client.firstName,
      last_name: client.lastName,
      original_company_id: client.originalCompanyId,
      companies: client.companies
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot
  ): Client {
    // TODO: would be good to find out what happens when any of the below
    // fields dont exist. They should for the most part,
    return Client.fromFirestoreDoc(snapshot);
  },
}

export default Client;
export { clientCoverter };
