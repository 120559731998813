import { initializeApp } from "firebase/app";
import { initializeFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { initializeAuth, browserLocalPersistence, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import env from "../env.json";

const app = initializeApp(env.firebaseConfig);
const db = initializeFirestore(app, {experimentalForceLongPolling: true});
const auth = initializeAuth(app, { persistence: browserLocalPersistence });
const functions = getFunctions(app);

// NOTE: doing the below check means that the whole app needs
// the emulators running to work properly
if (window.location.hostname.includes("192.168.1.11")) {
  console.log("initializing emulators...");
  connectAuthEmulator(auth, "http://192.168.1.11:9099");
  connectFirestoreEmulator(db, "192.168.1.11", 8080);
  connectFunctionsEmulator(functions, "192.168.1.11", 5001);
}

export { app, db, auth, functions };
