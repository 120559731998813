import React, { Component } from "react";

// Wrapper over PageNumbers & PageInfo
class Pagination extends Component {
  state = {};

  static getDerivedStateFromProps(props) {
    const isIterable = (object) =>
      object != null && typeof object[Symbol.iterator] === "function";
    if (!props.iterable) {
      throw new Error("must pass 'iterable' prop");
    }
    if (!props.numResults) {
      throw new Error("must pass 'numResults' prop");
    }
    if (!isIterable(props.iterable)) {
      throw new Error("iterable prop should actually be iterable");
    }
    return null;
  }

  render() {
    const children = React.Children.map(this.props.children, (child) => {
      if (child.type === PageNumbers) {
        return React.cloneElement(child, {
          iterable: this.props.iterable,
          currentPage: this.props.currentPage,
          numResults: this.props.numResults,
          changePage: this.props.changePage,
        });
      } else if (child.type === PageInfo) {
        return React.cloneElement(child, {
          iterable: this.props.iterable,
          currentPage: this.props.currentPage,
          numResults: this.props.numResults,
          changePage: this.props.changePage,
        });
      } else {
        return child;
      }
    });
    return <div style={this.props.style}>{children}</div>;
  }
}

function PageInfo(props) {
  const { iterable, currentPage, numResults } = props;

  const rangeMin = (currentPage - 1) * numResults;
  const rangeMax = (currentPage - 1) * numResults + numResults;

  return (
    <span style={props.style}>
      Showing {props.keyword || "things"}{" "}
      {iterable.length === 0 ? 0 : rangeMin + 1}-
      {Math.min(rangeMax, iterable.length)} out of {iterable.length}
    </span>
  );
}

class PageNumbers extends Component {
  buttonStyle = {
    padding: "0.25rem",
    margin: 0,
    fontSize: "13px",
  };

  clampNumber = (num, a, b) =>
    Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b));

  onClickHandler = (page) => {
    const numberOfPages = Math.ceil(
      this.props.iterable.length / this.props.numResults
    );
    page = this.clampNumber(page, 1, numberOfPages);
    if (this.props.changePage) {
      this.props.changePage(page);
    }
  };

  render() {
    const numberOfPages = Math.max(
      Math.ceil(this.props.iterable.length / this.props.numResults),
      1
    );
    return (
      <span style={this.props.style}>
        {/* previous button */}
        <span
          className={`btn btn-link btn-sm ${
            this.props.currentPage === 1 ? "disabled" : ""
          }`}
          style={this.buttonStyle}
          onClick={() => this.onClickHandler(this.props.currentPage - 1)}
          title={`Navigate to the previous page`}
        >
          Previous
        </span>

        {/* ... button */}
        <span
          className={"btn btn-link btn-sm"}
          style={{ padding: 0, margin: 0 }}
          onClick={() => this.onClickHandler(1)}
          title={"Navigate to page 1"}
        >
          {this.props.currentPage > 2 ? "..." : ""}
        </span>

        {/* page numbers */}
        {[...Array(numberOfPages).keys()]
          .filter((str) => {
            const page = this.props.currentPage;
            if (str === page) return true;
            if (str + 1 === page) return true;
            if (str + 2 === page) return true;
            return false;
          })
          .map((str) => (
            <button
              key={str}
              className={"btn btn-link btn-sm"}
              style={{
                ...this.buttonStyle,
                ...{
                  color: this.props.currentPage === str + 1 ? "black" : null,
                },
              }}
              onClick={() => this.onClickHandler(str + 1)}
              title={`Navigate to page ${str + 1}`}
            >
              {this.props.currentPage === str + 1 ? (
                <i>{str + 1}</i>
              ) : (
                `${str + 1}`
              )}
            </button>
          ))}

        {/* ... button here */}
        <span
          className={"btn btn-link btn-sm"}
          style={{ padding: 0, margin: 0 }}
          onClick={() => this.onClickHandler(numberOfPages)}
          title={`Navigate to page ${numberOfPages}`}
        >
          {this.props.currentPage < numberOfPages - 1 ? "..." : ""}
        </span>

        {/* next button */}
        <span
          className={`btn btn-link btn-sm ${
            this.props.currentPage === numberOfPages ? "disabled" : ""
          }`}
          style={this.buttonStyle}
          onClick={() => this.onClickHandler(this.props.currentPage + 1)}
          title={`Navigate to the next page`}
        >
          Next
        </span>
      </span>
    );
  }
}

export default Pagination;
export { PageInfo, PageNumbers };
