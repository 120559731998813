import React from "react";

function PageHeader(props) {
  const font_style = {
    fontFamily: "Montserrat, sans-serif",
    color: "#333",
    margin: 0,
  };
  return (
    <div style={{ marginBottom: "0.5rem", ...props.style }}>
      <p style={{ ...font_style, marginTop: "2rem" }}>
        {props.small ? props.small : ""}
      </p>
      <h1 className="font-weight-bold" style={{ margin: 0 }}>
        {props.big ? props.big : "..."}
      </h1>
      <i style={font_style}>{props.tiny ? `${props.tiny}` : ""}</i>
    </div>
  );
}

export default PageHeader;
