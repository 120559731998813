import { parse, isDate, isValid, isPast } from "date-fns";
import { useState, InputHTMLAttributes } from "react";
import InputMask from "react-input-mask";


interface ICustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  inputBoxLabel?: string;
  onValidDateChange?: (date: Date) => void;
  requiresFutureDate?: boolean;
  width?: string;
}

function DateInput(props: ICustomInputProps) {
  const [isDateValid, setIsDateValid] = useState(false);
  const [parsedDate, setParsedDate] = useState<Date>();
  const [dateInput, setDateInput] = useState("");

  console.log(dateInput);

  const parseDateFromDateInput = (input: string) => {
    if (input.includes("_")) return null;
    const parsedDate = parse(input, "MM/dd/uuuu", new Date());
    if (!isValid(parsedDate)) return null;
    if (props.requiresFutureDate && isPast(parsedDate)) {
      // date is in the past and its not supposed to be
      setIsDateValid(false);
      return null;
    };
    setIsDateValid(true);
    setParsedDate(parsedDate);
    return parsedDate;
    // parse date string and return a date object
    // if date object is valid, call onValidDateChange
    // do a check to see if date needs to be in the future
  }

  const updateState = (input: string) => {
    setDateInput(input);
    const parsedDate = parseDateFromDateInput(input);
    if (parsedDate && isValid(parsedDate) && props.onValidDateChange) {
      props.onValidDateChange(parsedDate);
    }
    console.log("parsedDate");
    console.log(isValid(parsedDate));
    console.log(props.onValidDateChange);

  };

  return (
    <>
      {props.inputBoxLabel ? (
        <span>
          <label htmlFor={props.name}>{props.inputBoxLabel}</label>
          <br />
        </span>
      ) : (
        <></>
      )}
      <input
        className="form-control"
        id={props.inputBoxLabel}
        style={{ width: props.width ? props.width : "100%" }}
        value={dateInput}
        onChange={(e) => updateState(e.target.value)}
      />
      {/* <InputMask
        mask="99/99/9999"
        // maskPlaceholder=" "
        alwaysShowMask={true}
        className="form-control"
        id={props.inputBoxLabel}
        style={{ width: props.width ? props.width : "100%" }}
        value={dateInput}
        onChange={(e) => updateState(e.target.value)}
      /> */}
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <p
          style={{
            fontSize: "1rem",
            marginLeft: ".75rem",
            marginBottom: ".5rem",
          }}
        >
          {parsedDate !== undefined ? parsedDate.toDateString() : ""}
        </p>
        <div
          style={{
            fontSize: "1rem",
            marginLeft: ".375rem",
            marginBottom: ".5rem",
          }}
        >
          {isDateValid ? (
            <i style={{ color: "green" }} className="fas fa-check"></i>
          ) : parsedDate ? (
            <i style={{ color: "red" }} className="fas fa-times"></i>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  )
}

export default DateInput;
