import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { functions } from "../../lib/firebase";

// TODO: this page is a wip

function InstallPage() {
  const [storeName, setStoreName] = useState("");
  const navigate = useNavigate();

  const installStore = (e: FormEvent) => {
    e.preventDefault();
    const installUrl = functions.customDomain;
    if (!installUrl) {
      console.error(
        `Can't find functions custom domain, expected string, instead got: ${installUrl} aborting`
      );
      return;
    }
    navigate(installUrl + `/install?shop=${storeName}`);
  };

  return (
    <div>
      <h2>Install SEP app into your Shopify store</h2>
      <p>
        Enter your store name into the input box below and click install.
        You will be redirected to your Shopify store to confirm the install.
      </p>
      <form onSubmit={e => installStore(e)}>
        <div style={{ marginBottom: "4px" }}>
          <label htmlFor="store-input">Enter your store name:</label>
        </div>
        <div style={{ marginBottom: "4px" }}>
          <input
            id="store-input"
            value={storeName}
            onChange={e => setStoreName(e.target.value)}
            type="text"
            placeholder="my-store-name"
          />
        </div>
        <div style={{ marginBottom: "4px" }}>
          <input
            type="submit"
            name="submit"
            value="Submit"
            onSubmit={e => installStore(e)}
          />
        </div>
      </form>
    </div>
  )
}

export { InstallPage };
