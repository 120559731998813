import { ChangeEvent } from "react";
import Client from "../../../../lib/uma/clients";
import LoadingCircle from "../../../misc/Loading";

interface IUsersCheckboxesProps {
  clients: Array<Client>;
  clientsSelected?: Array<Client>;
  onClientsUpdated: (selectedClients: Array<Client>) => void;
}

function UsersCheckboxes(props: IUsersCheckboxesProps) {
  const selectClient = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const addedClient = props.clients.find(
        (client) => client.docId === e.target.id
      );
      if (addedClient) {
        props.onClientsUpdated([...(props.clientsSelected || []), addedClient]);
      } else {
        console.error("couldn't find client");
      }
    } else {
      const newSelectedClients = (props.clientsSelected || []).filter(
        (client) => client.docId !== e.target.id
      );
      props.onClientsUpdated(newSelectedClients);
    }
  };

  if (props.clients === undefined) {
    return <LoadingCircle />;
  } else if (props.clients.length === 0) {
    return (
      <div
        className="alert alert-info"
        style={{ marginTop: "1.25rem", marginBottom: "0.5rem" }}
      >
        No clients found. You can still create the access and then create
        the clients and assign them this access afterwards.
      </div>
    );
  }

  return (
    <div>
      <p style={{ marginTop: "1.25rem", marginBottom: "0.5rem" }}>
        Select users to give the new access to:
      </p>
      {props.clients.map((client, i) => {
        return (
          <div key={i} style={{ display: "flex" }}>
            <div
              className="input-group mb-3"
              title={`Toggle access to ${client.email}`}
            >
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input
                    onChange={(e) => selectClient(e)}
                    id={client.docId}
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    defaultChecked
                  />
                </div>
              </div>
              <label htmlFor={client.docId} className="form-control">
                {client.email}
              </label>
            </div>
          </div>
        )
      })}
    </div>
  );
}

export default UsersCheckboxes;
export {};
