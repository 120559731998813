import { createContext, useContext, useState, useEffect } from "react";
import { auth } from "./firebase";
import { User } from "firebase/auth";

interface IAuthState {
  initializing: boolean;
  user: User | null;
}

const UserContext = createContext<IAuthState>({
  initializing: true,
  user: null,
});

const useSession = () => {
  const { user } = useContext(UserContext);
  return user;
};

const useAuth = () => {
  const [state, setState] = useState<IAuthState>(() => {
    const user = auth.currentUser;
    return {
      initializing: !user,
      user: user,
    };
  });

  useEffect(() => {
    const authUnsub = auth.onAuthStateChanged(
      (user) => {
        setState({ initializing: false, user: user });
      },
      (error) => {
        console.error(error);
      },
      () => {
        console.log("auth hook removed");
      }
    );

    return () => authUnsub();
  }, []);

  return state;
};

export { UserContext, useAuth, useSession };
