import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../lib/firebase";

interface IAccountDropdownProps {
  showExportsButton: boolean;
  logout: () => Promise<void>;
}

function AccountDropdown(props: IAccountDropdownProps) {
  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        position: "absolute",
        width: "100%",
      }}
    >
      {props.showExportsButton ? (
        <Link
          style={{ width: "100%", marginTop: "0.5rem" }}
          className="btn btn-light btn-sm"
          title={"Redirect to /exports"}
          to="/exports"
        >
          Exports
        </Link>
      ) : (
        <></>
      )}
      <button
        style={{ width: "100%", marginTop: "0.5rem" }}
        className="btn btn-light btn-sm"
        onClick={props.logout}
      >
        Log out
      </button>
    </div>
  );
}

function Navigation() {
  const [accountDropdown, setAccountDropdown] = useState(false);
  const [showExportsButton, setShowExportsButton] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const docRef = doc(db, `admin_settings/${auth.currentUser?.email}`);
    getDoc(docRef)
      .then((userSettingsDoc) => {
        if (userSettingsDoc.exists()) {
          const data = userSettingsDoc.data();
          if (data && data.allow_exports) {
            setShowExportsButton(true);
          }
        }
      }).catch((err) => console.log(err));
  }, []);

  const logout = async () => {
    await auth.signOut();
    navigate("/login");
  }

  const user = auth.currentUser;

  return (
    <div className="app-header">
      {user ? (
        <div className="m0 app-header-inner-wrapper">
          <span
            className="m0"
            style={{ position: "relative", marginRight: "auto" }}
          >
            <Link
              to="/companies/1"
              className="btn"
              style={{ color: "white", paddingLeft: 0 }}
            >
              User Management App
            </Link>
          </span>
          <span
            className="m0"
            style={{ position: "relative", marginLeft: "auto" }}
            onMouseEnter={() => setAccountDropdown(true)}
            onMouseLeave={() => setAccountDropdown(false)}
          >
            <button
              style={{ color: "white" }}
              className="btn"
              onClick={() => setAccountDropdown(!accountDropdown)}
            >
              {user.email} <span className="fas fa-caret-down"></span>
            </button>
            {accountDropdown
              ? <AccountDropdown showExportsButton={showExportsButton} logout={logout} />
              : <></>}
          </span>
        </div>
      ) : (
        <span />
      )}
    </div>
  );
}

export default Navigation;
