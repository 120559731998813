import React from "react";
import CustomModal, {
  ModalBody,
  ShowModalButton,
  CloseModalButton,
} from "./CustomModal";

function DeleteButton(props) {
  return (
    <CustomModal>
      <ShowModalButton
        className={`btn btn-light ${props.small ? "btn-sm" : ""}`}
        style={{ marginLeft: 0 }}
        title={props.title}
      >
        <i className="fas fa-trash"></i>
      </ShowModalButton>
      <ModalBody>
        <h5 className="font-weight-bold">
          Are you sure you want to delete this {props.object_name}?
        </h5>
        <span style={{ marginLeft: "auto" }}>
          {/* <button className="btn btn-danger btn-sm" onClick={props.delete}>
            Delete
          </button> */}
          <CloseModalButton
            className="btn btn-danger btn-sm"
            style={{ marginLeft: "1rem" }}
            onClick={props.delete}
          >
            Delete
          </CloseModalButton>
          <CloseModalButton
            className="btn btn-secondary btn-sm"
            style={{ marginLeft: "1rem" }}
          >
            Cancel
          </CloseModalButton>
        </span>
      </ModalBody>
    </CustomModal>
  );
}

export default DeleteButton;
