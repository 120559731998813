// NPM dependencies
import React, { Component } from "react";
import { Navigate } from "react-router-dom";

// Local dependencies
import PageHeader from "./misc/PageHeader";
import Breadcrumbs from "./misc/Breadcrumbs";
import LoadingCircle from "./misc/Loading";

// Context
import CloudFunctions from "../Context";
import DeleteButton from "./misc/Delete";
import Alert from "./misc/Alert";

import { db } from "../lib/firebase-compat";

// TODO: if anything goes wrong, export listing shows infinite loading circle, needs to be fixed

class ExportsPage extends Component {
  static contextType = CloudFunctions;
  constructor() {
    super();
    this.state = {
      redirect: null,
      exports: [],
    };
  }

  componentDidMount() {
    this.shopify = this.context;
    this.exportsUnsub = db
      .collection("exports")
      .orderBy("export_begun_timestamp", "desc")
      .onSnapshot((snapshot) => {
        this.setState({
          exports: snapshot.docs,
        });
      });
    // const currentUserSettings = db
    //   .collection("admin_settings")
    //   .doc(this.props.auth.currentUser.email)
    //   .get();
  }

  componentWillUnmount() {
    this.exportsUnsub();
  }

  newExport = async () => {
    const exports = db.collection("exports");
    const newExportDoc = exports.doc();
    await newExportDoc.set({
      file_name: "",
      export_begun_timestamp: new Date(),
      status: "pending",
    });
    const response = await this.shopify.exportUsers({
      admin: this.props.user.email,
      exportDocId: newExportDoc.id,
    });
    console.log(response);
  };

  deleteExport = async (id) => {
    const exports = db.collection("exports");
    const exportDoc = exports.doc(id);
    await exportDoc.update({
      status: "pending",
    });
    await this.shopify.deleteExport({
      exportDocId: id,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="split-view">
        <div className="m0 listings-wrapper left">
          <Breadcrumbs
            style={{ marginTop: "1rem" }}
            pathways={[["Companies", "/companies"]]}
          />
          <PageHeader big={"Exports"} />
          <div>
            <button
              className="btn btn-link"
              style={{ padding: "0.25rem", fontSize: "14px" }}
              onClick={this.newExport}
            >
              + Create New Export
            </button>
            {this.state.exports.length === 0 ? (
              <Alert
                msg={"No exports to display"}
                alertType={"alert alert-secondary"}
              />
            ) : (
              <React.Fragment />
            )}
            {this.state.exports.map((exportDoc) => {
              const data = exportDoc.data();
              return (
                <div className="listing" key={exportDoc.id}>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <span>{data.file_path}</span>
                    <span style={{ marginLeft: "0.5rem" }}>
                      {data.file_size ? `(${data.file_size})` : ""}
                    </span>
                    <span style={{ marginLeft: "auto" }}>
                      {data.status === "pending" ? (
                        <LoadingCircle style={{ marginTop: 0 }} small />
                      ) : (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <a
                            style={{
                              padding: "0.25rem",
                              marginRight: "0.5rem",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={data.signedUrl}
                            download
                          >
                            <i className="fas fa-file-download"></i>
                          </a>
                          <DeleteButton
                            object_name={"export"}
                            delete={() => this.deleteExport(exportDoc.id)}
                            title={`Delete export "${data.file_path}"`}
                            small
                          />
                        </span>
                      )}
                    </span>
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default ExportsPage;
