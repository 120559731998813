// NPM Dependencies
import React, { Component } from "react";
import { Navigate } from "react-router-dom";

// Local Dependencies
import CustomInput from "../../misc/CustomInput";
import Breadcrumbs from "../../misc/Breadcrumbs";
import Alert from "../../misc/Alert";
import PageHeader from "../../misc/PageHeader";

import { db } from "../../../lib/firebase-compat";

class NewCompanyPage extends Component {
  constructor(props) {
    super(props);
    this.input_ref = React.createRef();
    this.state = {
      redirect: null,
      companies: null,
      company_name: "",
      alert: {
        msg: "",
        alert_type: "alert-primary",
      },
    };
  }

  async componentDidMount() {
    this.input_ref.current.focus();
    const companies = await db.collection("companies").get();
    this.setState({
      companies: companies,
    });
  }

  componentWillUnmount() {
    this.cancelAlert();
  }

  setAlert = (msg, alert_type, time) => {
    this.cancelAlert();
    this.setState({
      alert: {
        msg: msg,
        type: alert_type,
      },
    });
    this.timer = setTimeout(() => {
      this.setState({
        alert: {
          msg: "",
        },
      });
    }, time);
  };

  cancelAlert = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  };

  submit = async (e) => {
    const all_companies = await db
      .doc("companies/_all_companies")
      .get();
    if (!all_companies.exists) {
      db.doc("companies/_all_companies").set({
        companies: [this.state.company_name],
      });
    } else {
      const data = all_companies.data();
      if (data && data.companies) {
        if (data.companies.includes(this.state.company_name)) {
          this.setAlert(
            "Company name already exists! Please choose another company name.",
            "warning",
            5000
          );
          return;
        }
        all_companies.ref.update({
          companies: [...(data.companies || []), this.state.company_name],
        });
      }
    }
    const split_name = this.state.company_name.split(" ");
    if (!split_name.filter((str) => str !== "").length > 0) {
      this.setAlert("Company name can not be empty!", "warning", 5000);
      return;
    }
    const new_company = db.collection("companies").doc();
    await new_company.set({ company_name: this.state.company_name });
    db.collection("companies_logs").add({
      log_type: "company_created",
      timestamp: new Date(),
      admin_id: this.props.user.uid,
      admin_email: this.props.user.email,
      specifics: {
        company_id: new_company.id,
        company_name: this.state.company_name,
      },
    });
    db.collection(`companies/${new_company.id}/logs`).add({
      log_type: "company_created",
      timestamp: new Date(),
      admin_id: this.props.user.uid,
      admin_email: this.props.user.email,
      specifics: {
        company_id: new_company.id,
        company_name: this.state.company_name,
      },
    });
    this.setState({
      redirect: `/company/${new_company.id}`,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      company_name: e.target.value,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="m0 listings-wrapper middle half-width">
        <Breadcrumbs
          style={{ marginTop: "1rem" }}
          pathways={[["Companies", "/companies"], ["New Company"]]}
        />
        <PageHeader big={"Add new company"} />
        <div style={{ marginTop: "1rem" }}>
          <CustomInput
            ref={this.input_ref}
            name={"company_name"}
            label={"Company Name"}
            value={this.state.company_name}
            onChange={this.handleInputChange}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "1rem",
            height: "2rem",
          }}
        >
          <Alert
            style={{ margin: 0 }}
            msg={this.state.alert.msg}
            alertType={this.state.alert.type}
          />
          <button
            className="btn btn-primary btn-sm"
            style={{ marginLeft: "auto" }}
            onClick={this.submit}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default NewCompanyPage;
