import Alert from "../../../misc/Alert";
import LoadingCircle from "../../../misc/Loading";

// TODO: move this into acess.ts
export interface IDims {
  displayName: string;
  columnId: string;
  columnName: string;
  dimTable: string;
  rows: Array<{
    id: string;
    value: string;
  }>;
}

interface IRLSDimsSelectorProps {
  rlsValues?: Array<IDims> | null;
  dimsSelected: Array<IDims>;
  updateRlsSelectedValues: (rlsDims: IDims[]) => void;
}

function RLSDimsSelector(props: IRLSDimsSelectorProps) {

  const {rlsValues, dimsSelected , updateRlsSelectedValues} = props;

  const selectDimension = (
    row: { id: string, value: string },
    dim: IDims,
    checked: boolean
  ) => {
    if (!rlsValues) return;

    const newSelectedDim = (dimsSelected.find((d) => d.columnName === dim.columnName) || {
      ...dim,
      rows: []
    });

    if (checked) {
      newSelectedDim.rows.push(row);
      updateRlsSelectedValues([
        ...dimsSelected.filter((d) => d.columnName !== dim.columnName),
        newSelectedDim
      ]);
    } else {
      newSelectedDim.rows = newSelectedDim.rows.filter((val) => val.id !== row.id);
      updateRlsSelectedValues([
        ...dimsSelected.filter((d) => d.columnName !== dim.columnName),
        newSelectedDim
      ]);
    }
  };

  if (rlsValues === undefined) {
    return (
      <div>
        <p style={{ marginTop: "1.25rem", marginBottom: "0.5rem" }}>
          Loading RLS Dimensions...
        </p>
        <LoadingCircle />
      </div>
    );
  }

  if (rlsValues === null) {
    return (
      <Alert 
        msg="Something went wrong when trying to fetch the RLS dimensions. Please contact the tech team (either Amar Oklopcic or Ryan Flynn) for support."
        alertType="danger"
        style={{ width: "100%" }}
      />
    );
  }

  return (
    <div>
      <p style={{ marginTop: "1.25rem", marginBottom: "0.5rem" }}>
        Select dimensions to give access to:
      </p>
      {rlsValues.map((dim, i) => {
        return <div key={i}>
          {dim.rows.map((row, i) => {
            return (
              <div key={i} style={{ display: "flex" }}>
                <div
                  className="input-group mb-3"
                  title={`Toggle access to ${dim.displayName}`}
                >
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <input
                        onChange={(e) => {
                          selectDimension(row, dim, e.target.checked)
                        }}
                        id={row.id}
                        type="checkbox"
                        aria-label="Checkbox for following text input"
                        defaultChecked={false}
                      />
                    </div>
                  </div>
                  <label htmlFor={row.id} className="form-control">
                    {row.value}
                  </label>
                </div>
              </div>
            )
          })}
        </div>
      })}
    </div>
  );
}

export default RLSDimsSelector;
export {}
