// NPM dependencies
import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";

// Local dependencies
import CustomInput from "../../../misc/CustomInput";
import Alert from "../../../misc/Alert";
import Breadcrumbs from "../../../misc/Breadcrumbs";
import PageHeader from "../../../misc/PageHeader";
import LoadingCircle from "../../../misc/Loading";
import { db } from "../../../../lib/firebase-compat";

// Context
import CloudFunctions from "../../../../Context";

class NewUserSinglePage extends Component {
  static contextType = CloudFunctions;
  constructor(props) {
    super(props);
    this.selected_placeholder = [
      ["Michael", "Scott", "mscott@dmifflin.com"],
      ["Dwight", "Schrute", "dschrute@dmifflin.com"],
      ["Jim", "Halpert", "jhalpert@dmifflin.com"],
      ["Pam", "Beesly", "pbeesly@dmifflin.com"],
    ][Math.floor(Math.random() * 4)];
    this.state = {
      doc: null, // parent company doc
      products: [], // list of all products available
      accesses: null, // all products company has access to (including duplicates)
      users: [], // list of all users across all companies
      user_exists: null, // is set to an obj containing a users email + firestore_user_id if the email input matches an existing user
      user_exists_locally: false, // true or false depending on if the email input matches an existing user within this company
      inputs: {}, // empty object until user starts editing the input fields
      alert: { msg: "", type: null }, // used to give feedback to user
      checkboxes: {}, // checkboxes that are toggled are stored here
      redirect: null, // used for telling the routing system it's time to switch pages
    };
  }

  initializeListeners = () => {
    const companiesCollection = db.collection("companies");
    const productsCollection = db.collection("products");
    const usersCollection = db.collection("users");

    const doc = companiesCollection.doc(this.props.companyId);
    const allUsers = usersCollection.doc("_all_users");
    const companyAccesses = doc.collection("access");

    this.productsUnsub = productsCollection.onSnapshot((snapshot) => {
      this.setState({
        products: snapshot.docs,
      });
    });
    this.companyAccessUnsub = companyAccesses.onSnapshot((snapshot) => {
      const accesses = snapshot.docs.filter(
        (sub) => sub.data().is_paused !== true
      );
      const accessIDs = accesses.map((sub) => sub.data().product_id);
      this.setState({ accesses: accesses, accessIDs: accessIDs });
    });
    this.allUsersUnsub = allUsers.onSnapshot((snapshot) => {
      this.setState({
        users: snapshot.exists ? snapshot.data().users : [],
      });
    });

    // fetch company details once
    doc.get().then(
      (snapshot) => this.setState({ doc: snapshot }),
      (err) => console.log(`encountered error: ${err}`)
    );
  };

  // fetch document details from Firestore and initialize listeners
  async componentDidMount() {
    console.log(this.props);
    this.shopify = this.context;
    this.initializeListeners();
  }

  componentWillUnmount() {
    if (this.productsUnsub) this.productsUnsub();
    if (this.companyAccessUnsub) this.companyAccessUnsub();
    if (this.allUsersUnsub) this.allUsersUnsub();
    if (this.cancelAlert) this.cancelAlert();
  }

  getProduct = (pid) => {
    return this.state.products.filter((product) => {
      return product.id === pid;
    })[0];
  };

  setAlert = (msg, alertType, time) => {
    this.cancelAlert();
    this.setState({
      alert: {
        msg: msg,
        type: alertType,
      },
    });
    this.timer = setTimeout(() => {
      this.setState({
        alert: {
          msg: "",
        },
      });
    }, time);
  };

  cancelAlert = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.setState({
      alert: {
        msg: "",
      },
    });
  };

  handleInputChange = (e) => {
    const email = e.target.value.toLowerCase();

    this.setState({
      inputs: {
        ...this.state.inputs,
        [e.target.id]: e.target.value,
      },
    });

    if (e.target.id === "email") {
      // check if user email exists within this company
      let local_emails = this.state.doc.data().users_emails || [];
      local_emails = local_emails.map((email) => email.toLowerCase());
      const user_exists_locally = local_emails.includes(email);
      if (user_exists_locally) {
        this.setAlert(
          `A user with this email already exists within this company! Emails within a company must be unique.`,
          "danger",
          60000
        );
      } else {
        this.cancelAlert();
      }

      // check if user email exists globally across all companies
      const emails = this.state.users.map((user) => user.email.toLowerCase());
      const user_exists = emails.includes(email)
        ? this.state.users.filter((user) => user.email === email)[0]
        : null;
      if (user_exists && !user_exists_locally) {
        this.setAlert(
          `A user with this email already exists. You can still create the user within this company, but you'll only be able to edit the access of the user that this company provides.`,
          "warning",
          60000
        );
      } else if (!user_exists && !user_exists_locally) {
        this.cancelAlert();
      }

      this.setState({
        user_exists: user_exists,
        user_exists_locally: user_exists_locally,
      });
    }
  };

  handleCheckboxChange = (e) => {
    this.setState({
      checkboxes: {
        ...this.state.checkboxes,
        [e.target.id]: e.target.checked,
      },
    });
  };

  addCompanyLog = (specifics) => {
    return this.state.doc.ref.collection("logs").add({
      log_type: "user_added",
      status: "pending",
      timestamp: new Date(),
      admin_id: this.props.user.uid,
      admin_email: this.props.user.email,
      specifics: {
        company_id: this.state.doc.id,
        ...specifics,
        // user_id: new_user.id,
        // user_details: this.state.inputs,
        // access_granted: new_access.map((access) => access.name),
      },
    });
  };

  validateEmail = (email) => {
    // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  submit = async () => {
    if (this.state.accessIDs && this.state.accessIDs.length === 0) {
      return this.setAlert(
        "Cannot create user without a company access being created first!",
        "warning",
        5000
      );
    }

    if (!this.state.inputs.first_name) {
      return this.setAlert("Missing user first name!", "warning", 5000);
    } else if (!this.state.inputs.last_name) {
      return this.setAlert("Missing user last name!", "warning", 5000);
    } else if (!this.state.inputs.email) {
      return this.setAlert("Missing user email!", "warning", 5000);
    }

    if (!this.validateEmail(this.state.inputs.email)) {
      return this.setAlert("Invalid email address!", "warning", 5000);
    }

    // make sure user does not already exist within the company
    if (this.state.user_exists_locally) {
      return null;
    }

    // created another array housing just emails on the company doc
    // so searching for emails from /companies page can work more efficiently
    let cached_user_emails = this.state.doc.data().users_emails || [];
    this.state.doc.ref.update({
      users_emails: [...cached_user_emails, this.state.inputs.email],
    });

    const new_access = this.state.accessIDs
      .filter((id) => id !== "")
      .filter((product) => {
        return this.state.checkboxes[product] !== false;
      })
      .filter((id) => {
        const access = this.state.accesses.filter(
          (access) => id === access.data().product_id
        )[0];
        const isExpired =
          access.data().expiration_date.toDate().setHours(23, 59, 0) <
          Date.now();
        if (isExpired) {
          return false;
        }
        return true;
      });

    // check here if user already exists
    // if they do, update the company email cache to include this new user
    // update the users access to show they have access to different products
    // update the users access history so we know how to re-give user access once company accesses are re-enabled
    if (this.state.user_exists && this.state.user_exists.firestore_user_id) {
      const user = await db
        .doc(`users/${this.state.user_exists.firestore_user_id}`)
        .get();
      const old_user_data = user.data();
      if (user.exists) {
        const combined_access = {
          ...old_user_data.access,
          ...{ [this.state.doc.id]: new_access },
        };
        user.ref.update({
          companies: [...old_user_data.companies, this.state.doc.id],
          access: combined_access,
          access_history: {
            ...old_user_data.access_history,
            ...{ [this.state.doc.id]: new_access },
          },
        });
        this.addCompanyLog({
          user_id: user.id,
          user_details: this.state.inputs,
          access_granted: new_access.map(
            (access) => this.getProduct(access).data().product_name
          ),
        }).then((new_log) => {
          this.shopify
            .updateCustomer({
              new_access: Object.keys(combined_access)
                .map((company_id) => combined_access[company_id])
                .reduce((acc, arr) => acc.concat(arr))
                .map(
                  (access_id) =>
                    this.getProduct(access_id).data().shopify_product_id
                ),
              // new_details: this.state.inputs,
              customer_id: old_user_data.associated_shopify_customer
                ? old_user_data.associated_shopify_customer.customer.id
                : -1,
              company_id: this.state.doc.id,
              companies: [...old_user_data.companies, this.state.doc.id],
              user_id: user.id,
              log_id: new_log.id,
            })
            .then((response) => {
              console.log(response);
              if ("error" in response) {
                new_log.update({
                  status: "failed",
                  status_message: response.error,
                });
              } else {
                new_log.update({
                  status: "in_queue",
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        console.log("User no longer valid");
      }
    } else {
      let new_user = db.collection("users").doc();
      new_user.set({
        first_name: this.state.inputs["first_name"],
        last_name: this.state.inputs["last_name"],
        email: this.state.inputs["email"],
        original_company_id: this.state.doc.id,
        companies: [this.state.doc.id],
        access: { [this.state.doc.id]: new_access },
        access_history: { [this.state.doc.id]: new_access }, // access that is re-applied later when the company regains access
      });
      // handle RLS accesses
      const rlsAccesses = this.state.accesses
        .filter((accessDoc) => accessDoc.data().rls)
        .map((accessDoc) => accessDoc.data());
      console.log(this.state.products);
      rlsAccesses.forEach((rlsAccess) => {
        console.log(rlsAccess);
        const rlsProduct = this.getProduct(rlsAccess.product_id);
        console.log(rlsProduct)
        rlsAccess.rls.forEach((rlsDim) => {
          const rlsGrant = {
            accessTable: rlsProduct.data().rls.accessTable, // TODO: we fucked up adding the RLSDims to the access
            userId: new_user.id,
            dimName: rlsDim.dimTable.split(".")[2], // d_location
            dimValues: rlsDim.rows // ["Illinois", "California", etc.]
          };
          this.shopify.grantRlsAccessToClient(rlsGrant).then((result) => {
            console.log("RLS grant successful");
            console.log(result);
          }).catch((err) => {
            console.log("RLS grant failed");
            console.log(err);
          });
        });
      });
      // set user email in our email cache doc
      db
        .doc("users/_all_users")
        .get()
        .then((doc) => {
          const users = doc.exists ? doc.data().users : [];
          if (doc.exists) {
            doc.ref.update({
              users: [
                ...users,
                {
                  email: this.state.inputs.email,
                  firestore_user_id: new_user.id,
                },
              ],
            });
          } else {
            db.doc("users/_all_users").set({
              users: [
                {
                  email: this.state.inputs.email,
                  firestore_user_id: new_user.id,
                },
              ],
            });
          }
        });
      this.addCompanyLog({
        user_id: new_user.id,
        user_details: this.state.inputs,
        access_granted: new_access.map(
          (access) => this.getProduct(access).data().product_name
        ),
      }).then((new_log) => {
        this.shopify
          .createCustomer({
            first_name: this.state.inputs["first_name"],
            last_name: this.state.inputs["last_name"],
            email: this.state.inputs["email"],
            access: new_access.map(
              (access) => this.getProduct(access).data().shopify_product_id
            ),
            companies: [this.state.doc.id],
            company_id: this.state.doc.id,
            user_id: new_user.id,
            log_id: new_log.id,
          })
          .then((response) => {
            if ("error" in response) {
              new_log.update({
                status: "failed",
                status_message: response.error,
              });
            } else {
              new_log.update({
                status: "in_queue",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
    this.setState({
      redirect: `/company/${this.state.doc.id}`,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    const accessIDs = this.state.accessIDs;
    let data = this.state.doc ? this.state.doc.data() : null;
    let id = this.state.doc ? this.state.doc.id : "";
    return (
      <div className="m0 listings-wrapper middle half-width">
        <Breadcrumbs
          style={{ marginTop: "1rem" }}
          pathways={[
            ["Companies", "/companies"],
            ["Company", `/company/${id}`],
            ["New User"],
          ]}
        />
        <PageHeader
          small={"New User"}
          big={data ? data.company_name : ""}
          tiny={id}
        />

        <div
          style={{
            display: accessIDs && accessIDs.length > 0 ? "block" : "none",
          }}
        >
          <div style={{ marginTop: "0.5rem" }}>
            <CustomInput
              name={"first_name"}
              label={"First Name"}
              onChange={this.handleInputChange}
              placeholder={this.selected_placeholder[0]}
              value={this.state.inputs.first_name}
              type={"text"}
              title={"Enter users first name"}
            />
          </div>
          <div style={{ marginTop: "0.5rem" }}>
            <CustomInput
              name={"last_name"}
              label={"Last Name"}
              onChange={this.handleInputChange}
              placeholder={this.selected_placeholder[1]}
              value={this.state.inputs.last_name}
              type={"text"}
              title={"Enter users last name"}
            />
          </div>
          <div style={{ marginTop: "0.5rem" }}>
            <CustomInput
              name={"email"}
              label={"Email"}
              onChange={this.handleInputChange}
              placeholder={this.selected_placeholder[2]}
              value={this.state.inputs.email}
              type={"text"}
              title={"Enter users email"}
            />
          </div>
        </div>

        <div style={{ marginTop: "1rem" }}>
          {accessIDs === undefined ? <LoadingCircle /> : <React.Fragment />}
          {accessIDs && accessIDs.length < 1 ? (
            <div style={{ marginBottom: 0 }} className="alert alert-warning">
              There are no products available to assign to this user because
              there isn't any accesses set up for this company yet. Click
              <Link to={`/company/${this.props.companyId}/new-access`}>
                <b> here </b>
              </Link>
              to create a new access for this company.
            </div>
          ) : (
            <React.Fragment>
              {this.state.accesses ? (
                <React.Fragment>
                  <p style={{ marginBottom: ".5rem" }}>
                    Products available to this user are based on the
                    subscriptions created for this company:
                  </p>
                  {this.state.accesses.map((product, i) => {
                    const productID = product.data().product_id;
                    const fetched_product = this.getProduct(productID);
                    const p_name = fetched_product
                      ? fetched_product.data().product_name
                      : null;
                    const isExpired =
                      product
                        .data()
                        .expiration_date.toDate()
                        .setHours(23, 59, 0) < Date.now();
                    if (!p_name) return <React.Fragment key={i} />;
                    return (
                      <div key={i} style={{ display: "flex" }}>
                        <div
                          className="input-group mb-3"
                          style={{ marginRight: "1rem" }}
                          title={`Toggle access to ${p_name}`}
                        >
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <input
                                onChange={this.handleCheckboxChange}
                                id={productID}
                                type="checkbox"
                                aria-label="Checkbox for following text input"
                                defaultChecked={!isExpired}
                                disabled={isExpired}
                              />
                            </div>
                          </div>
                          <label htmlFor={productID} className="form-control">
                            {p_name}
                            {isExpired ? (
                              <span
                                style={{
                                  color: "darkred",
                                  marginLeft: "0.25rem",
                                }}
                              >
                                (expired)
                              </span>
                            ) : (
                              <React.Fragment />
                            )}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}
            </React.Fragment>
          )}
        </div>

        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}
        >
          <Alert
            style={{ marginRight: "1rem" }}
            msg={this.state.alert.msg}
            alertType={this.state.alert.type}
          />
          <div style={{ marginLeft: "auto" }}>
            <button
              className="btn btn-primary btn-sm"
              onClick={this.submit}
              title={"Create new user"}
              disabled={this.state.user_exists_locally}
            >
              Create User
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default NewUserSinglePage;
